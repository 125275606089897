const OnboardingQuestions = [
  {
    question: "What's your primary motivation for purchasing a property?",
    options: [
      "First time home ownership",
      "Upgrading living space",
      "Investment opportunity",
      "Relocation (family, work, other reason)",
    ],
    key: "motivation",
    name: "Motivation",
  },
  {
    question: "What location are you interested in?",
    key: "location",
    name: "Location",
    type: "text",
  },
  {
    question: "What's your preferred property type?",
    options: [
      "Single family home",
      "Townhome",
      "Condo/Apartment",
      "Multi-family",
      "Other",
    ],
    key: "propertyType",
    name: "Property Type",
  },
  {
    question: "How many bedrooms do you prefer?",
    options: ["1", "2", "3", "4+"],
    key: "bedrooms",
    name: "Bedrooms",
  },
  {
    question: "How many bathrooms do you prefer?",
    options: ["1", "2", "3", "4+"],
    key: "bathrooms",
    name: "Bathrooms",
  },
  {
    question: "What is your target price range?",
    options: ["500k-750k", "750k-1M", "1M-1.5M", "1.5M-1.8M", "2M+"],
    key: "priceRange",
    name: "Price Range",
  },
  {
    question: "Rank the following in order of importance",
    options: [
      "Location/Neighborhood",
      "Property size",
      "Price",
      "School district",
      "Potential for appreciation",
      "Proximity to amenities",
    ],
    key: "ranking",
    type: "ranking",
    name: "Ranking",
  },
  {
    question: "Select the 3 most important amenities to you",
    options: [
      "Parks & green space",
      "Shopping centers",
      "Restaurants",
      "Entertainment",
      "Fitness facilities",
      "Healthcare services",
      "Good schools",
      "Nightlife",
      "Other",
    ],
    key: "topAmenities",
    type: "multi-select",
    name: "Top Amenities",
  },
  {
    question: "How soon are you looking to buy?",
    options: [
      "Immediately",
      "Within 3 months",
      "Within 6 months",
      "Within 1 year",
      "Just browsing",
    ],
    key: "buyTimeline",
    name: "Buy Timeline",
  },
];

export default OnboardingQuestions;
